import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby";

import styles from "./hero.module.css";
import image from "../../../content/assets/code-resized.jpg";

// import { initialize } from "./heroscripts.js";


class Hero extends React.Component<any> {
    // todo optimize image
    render() {
        return <div className={styles.heroWrapper} style={{ backgroundImage: `url(${image})` }}>
            <div className={styles.intro}>
                <h1>Karol P. Kozak</h1>
                <p>No taglines &mdash; I'm a developer, not a&nbsp;copywriter. Just check out my stuff below</p>
            </div>
            <a onClick={() => {document.getElementById("About")?.scrollIntoView(true)}}>
                <FontAwesomeIcon icon={faChevronDown}/>
            </a>
        </div>
    }
}

export default Hero;