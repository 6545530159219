import React from "react";
import { ContactIcons, ContactIconsVariant } from "../contactIcons/contactIcons";
import styles from "./contactCTA.module.css";

export const ContactCTA = (props: any) => {
    return <div className={styles.contactCTA} >
        <div className={styles.wrapper} >
            <h2>Want to talk, or work with me?</h2>
            <h3>Drop me a message</h3>

            <ContactIcons variant={ContactIconsVariant.HOMEPAGE} />
        </div>
    </div>
}