import React from "react";
import styles from "./experience.module.css";
import { ProjectLink } from "../projects/projectLink";
import { GetPortfolioData, IPortfolioItem, IPortfolioItemGroup, Tag } from "../../../../content/portfolioData";

export class Experience extends React.Component<{}, {}> {
    render() {
        const portfolioData = GetPortfolioData();
        const items = portfolioData.map(itemData => this.renderItemGroup(itemData));

        return <div className={styles.experience}>
            <div className={styles.content}>
                {items}
            </div>
        </div>
    }

    renderItemGroup(group: IPortfolioItemGroup) {
        const items = group.items.map(item => this.renderItem(item));

        return <div className={styles.itemGroup}>
            <div className={styles.years}>{group.startYear && group.startYear + " - "}{group.endYear}</div>
            <div className={styles.itemGroupTitle}>{group.title}</div>
            {items}
        </div>
    }

    renderItem(item: IPortfolioItem) {
        const tags = item.tags.map(tag => this.renderTag(tag))

        return <div className={styles.item}>
            <div className={styles.itemContent}>
                <div className={styles.header}>
                    <h2 className={styles.companyName}>{item.company}</h2>
                    <h3 className={styles.projectName}>{item.name}</h3>

                    <div>{tags}</div>
                </div>
                <div className={styles.description}>{item.description}</div>
                {item.links
                    && <div className={styles.links}>
                            {item.links?.map(link => <ProjectLink model={link} />)}
                    </div>
                }
            </div>
        </div>
    }

    renderTag(tag: Tag): any {
        return <span className={styles.tag}>{tag.name}</span>;
    }
}