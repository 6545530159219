export enum Icon {
    GITHUB,
    NUGET,
    NPM,
    LINK
}

export interface ILink {
    icon: Icon,
    url: string
}

export interface IGithubProject {
    name: string;
    description: string;
    path: string;
    isFeatured: boolean
    links?: ILink[]
}

export function getProjectsData(): IGithubProject[] {
    return [
        {
            name: "Forte.EpiResponsivePicture",
            description: "Smart responsive images in EPiServer with ImageResizing.Net and Focal point",
            path: "fortedigital/EpiResponsivePicture",
            isFeatured: true,
            links: [
                {
                    url: "https://github.com/fortedigital/EpiResponsivePicture/",
                    icon: Icon.GITHUB
                },
                {
                    url: "https://www.nuget.org/packages/Forte.EpiResponsivePicture/",
                    icon: Icon.NUGET
                },
                {
                    url: "https://www.npmjs.com/package/epiresponsivepicture-react",
                    icon: Icon.NPM
                }
            ]
        },
        {
            name: "Responsive picture sample",
            description: "Sample project created as a showcase of Forte.EpiResponsivePicture package" ,
            path: "kpkozak/epi-responsive-picture-sample",
            isFeatured: true,
            links: [
                {
                    url: "https://github.com/kpkozak/epi-responsive-picture-sample",
                    icon: Icon.GITHUB
                }
            ]
        },
        // {
        //     name: "softbody-physics",
        //     description: "Master thesis project at AGH University of Science and technology. 2D soft body game physics engine.",
        //     path: "kpkozak/epi-responsive-picture-sample",
        //     isFeatured: true,
        //     links: [
        //         {
        //             url: "https://github.com/kpkozak/softbody-physics",
        //             icon: Icon.GITHUB
        //         }
        //     ]
        // },
        {
            name: "Forte.EpiserverRedirects",
            description: "Automatic redirects for EPiServer",
            path: "fortedigital/EpiserverRedirects",
            isFeatured: false,
            links: [
                {
                    url: "https://github.com/fortedigital/EpiserverRedirects",
                    icon: Icon.GITHUB
                },
                {
                    url: "https://www.nuget.org/packages/Forte.EpiserverRedirects/",
                    icon: Icon.NUGET
                }
            ]
        },
        {
            name: "Forte.EpiCommonUtils",
            description: "A set of common utils to make your day to day EpiServer development easier",
            path: "fortedigital/epicommonutils",
            isFeatured: false,
            links: [
                {
                    url: "https://github.com/fortedigital/epicommonutils",
                    icon: Icon.GITHUB
                },
                {
                    url: "https://www.nuget.org/packages/Forte.EpiCommonUtils/",
                    icon: Icon.NUGET
                }
            ]
        }
    ];
}