import React from "react";
import { getSkillset } from "../../../content/skillsData";
import { Skills } from "./skills";
import styles from "./about.module.css";
import photo from "../../../content/assets/photo-cropped.jpg";

export class About extends React.Component {
    constructor(props: any) {
        super(props);
    }
    render() {

        const skills = <section className={styles.skillsWrapper}>
            <div className={styles.image}><img src={photo} alt="My photo" /></div>
            <Skills skills={getSkillset()} />
        </section>;
        const years = Math.floor((((new Date().getTime())-new Date(2014,7).getTime()))/(1000 * 60 * 60 * 24)/365)
        return <article className={styles.wrapper}>
            <h2>About me</h2>
            <div className={styles.content}>
                {skills}
                <section className={styles.description}>
                <p>
                    I'm a full stack software developer with over {years} years of professional experience, specialized in Microsoft .Net tech stack. During that time I was developing wide variety of software &mdash; Web applications, APIs, desktop apps, backend services or even libraries. 
                </p>
                <p>
                    I have worked in various projects in different industries, like healthcare, finance, industrial (wooden industry) or e-commerce, participating in projects of various size and ways of work. I&nbsp;contributed my work in both big teams, and small, Agile, <em>scrumy</em>, rapid-development oriented ones. I&nbsp;also delivered the projects where most of <em>around-development</em> work was done entirely by myself.
                </p>
                <p>
                    In everyday work, I&nbsp;am highly focused on providing best quality software, with clean, readable and extensible code following SOLID principles. At the same time I have strong attention to resolving real business needs and do the work that is really needed, in the right order. I&nbsp;am always trying to see whole business perspective to better understand the project, its challenges and choices. I&nbsp;am also always looking for a way to improve my work on all aspects &mdash; the product, code quality and the process itself. 
                </p>
                <p>
                    On a technical side, lastly I mostly work on a Web applications within Microsoft ecosystem &mdash; .NET (.NET Core, ASP.NET MVC), powered by relational (or non-relational) databases and Javascript, or &mdash; preferably &mdash; TypeScript. I&nbsp;am also fan of flexible, <em>dead-simple</em> and <em>framework-less</em> approach of ReactJS, so this is my default choice for Web frontend work. 
                </p>
                <p>
                    Privately, I was infected with the programming bug in my early years, playing with Basic at the age of 12. Actually, I&nbsp;started to learn programming and was creating my first video games with friends at high school. From that time game development is still kind of my hobby, to which I&nbsp;come back from time to time, doing some tiny projects after hours.
                </p>
                </section>
            </div>
        </article>
    }
}