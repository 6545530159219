export interface ISkill {
    label: string;
    level: number;
}

export function getSkillset() {
    return [
        {
            label: "C#",
            level: 100
        },
        {
            label: ".Net",
            level: 100
        },
        {
            label: "SQL",
            level: 70
        },
        {
            label: "JS / TS",
            level: 60
        },
        {
            label: "React",
            level: 65
        },
        {
            label: "CSS",
            level: 30
        }
    ]
}