import React from "react";
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./projects.module.css";
import { ProjectLink } from "./projectLink";
import { IGithubProject } from "../../../../content/projectsData";

export const Projects = (props: {model: IGithubProject[]}) => {
    const items = props.model.map(project => getProjectCard(project));
    return <ul className={styles.list}>
                {items}    
            </ul>;
}

function getProjectCard(project: IGithubProject) {
    return <li key={project.name}
               className={styles.card}>
        <span className={styles.icon}><FontAwesomeIcon icon={project.isFeatured ? faUser : faUsers}/></span>
        
        <ul className={styles.details}>
            <li>
                <span>project:</span>
                <span>{project.name}</span>
            </li>
            <li>
                <span>description:</span>
                <span>{project.description}</span>
            </li>           
        </ul>
        <div className={styles.links}>
            {project.links && project.links?.map(link => <ProjectLink model={link} />)}
        </div>
    </li>
}
