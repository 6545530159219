import styles from "./work.module.css";
import React from "react";
import { Projects } from "./projects/projects";
import { getProjectsData } from "../../../content/projectsData";
import { Experience } from "./experience/experience";
import { Tab, Tabs } from "./tabs/tabs";

export const Work = (props: any) => <div className={styles.work}>
    <Tabs>
        <Tab heading="Experience">
            <Experience />
        </Tab>
        <Tab heading="Open source" subheading="Some open source works I created or contributed to">
            <Projects model={getProjectsData()} />
        </Tab>
    </Tabs>

    </div>

