import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./tabs.module.css";
export interface TabProps {
    children: JSX.Element[] | JSX.Element;
    heading: string;
    subheading?: string;
}

export const Tab = (props: TabProps) => {
    return <div>{props.children}</div>
}
export const Tabs = (props:{children: JSX.Element[]}) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    const tabs = props.children.map((tab, i) => 
        <InternalTab tab={tab} 
                     isSelected={i==selectedTabIndex} 
                     onClick={async () => setSelectedTabIndex(i)} />);
    return <div className={styles.tabs}>{tabs}</div>
}

export const InternalTab = (props: {tab: JSX.Element, isSelected:boolean, onClick: () => {}}) => {
    
    const [isBeforeAnimation, setIsBeforeAnimation] = useState<boolean>(true);
    const {isSelected, onClick, tab} = props;
    const tabProps = tab.props as TabProps;
    
    useEffect( () => {
        setIsBeforeAnimation(true);
        setTimeout(() => setIsBeforeAnimation(false),100);
    }, [props.isSelected]);


    return <div className={classNames(isSelected ? styles.selected : styles.unselected, { "beforeAnimation": isBeforeAnimation})}>
        <button onClick={onClick}><h2 className={styles.tabHeading}>{tabProps.heading}</h2></button>
        {tabProps.subheading && <h3 className={styles.tabSubheading}>{tabProps.subheading}</h3>}
        <div className={styles.content}>
            {tabProps.children}
        </div>
    </div>
}
