import React from "react";
import { ISkill } from "../../../content/skillsData";
import { isInViewport, scrolledAtLeastTo } from "../../utils/viewportUtils";
import styles from "./skills.module.css";

export class Skills extends React.Component<{ skills: ISkill[] }, {animationFired: boolean}> {
  private wrapperRef;
  private timeout;
  constructor(props:any) {
    super(props);

    this.wrapperRef = React.createRef<HTMLElement>();

    this.state = {
      animationFired: false
    }

    this.handleAnimation = this.handleAnimation.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.handleAnimation();
      window.addEventListener("scroll", this.handleAnimation)
    }, 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    window.removeEventListener("scroll", this.handleAnimation);
  }
  
  handleAnimation() {
    const inViewport = isInViewport(this.wrapperRef.current);
    if(inViewport && !this.state.animationFired) {
      this.setState({animationFired: true});
    }
  }

  render() {
    const skills = this.props.skills.map((skill,i) => (<li key={skill.label}>
      <div className={styles.label}>{skill.label}</div>
      <div className={styles.barContainer} >
        <div className={styles.bar} style={{width:this.state.animationFired?skill.level+"%":"0", transitionDelay:0.1*i+"s"}}>&nbsp;</div>
      </div>
    </li>));

    return <div><ul className={styles.chartSkills} ref={this.wrapperRef}>
      {skills}
    </ul>
    </div>
  }
}