import { faGithub, faNpm } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Icon, ILink } from "../../../../content/projectsData";
import nugetIcon from "./nuget.svg";
import styles from "./projects.module.css";

export const ProjectLink = (props: {model: ILink}) => {
    const link = props.model;
    const icon = mapIcon(link.icon);
    return <div key={link.url}
                className={styles.link}>
        <a href={link.url} target="_blank">
            {icon}
        </a>   
    </div>
}

function mapIcon(icon: Icon) {
    switch(icon) {
        case Icon.GITHUB: return <FontAwesomeIcon icon={faGithub} title={"GitHub"}/>
        case Icon.NPM: return <FontAwesomeIcon icon={faNpm} title={"npm"}/>;
        case Icon.NUGET: return <img src={nugetIcon} title="NuGet" width="32px"/> // https://commons.wikimedia.org/wiki/File:NuGet_project_logo.svg
        case Icon.LINK: return <FontAwesomeIcon icon={faExternalLinkAlt} title={"external link"} />
    }
}