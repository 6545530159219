import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { getProjectsData } from "../../content/projectsData";
import { About } from "../components/about/about";
import Hero from "../components/hero/hero";
import Layout from "../components/layout";
import { Projects } from "../components/work/projects/projects";
import { ContactCTA } from "../components/contactCTA/contactCTA";
import SEO from "../components/seo";
import { NewsletterForm } from "../components/newsletterForm/newsletterForm";
import { Portfolio } from "../components/work/portfolio/portfolio";
import { GetPortfolioData } from "../../content/portfolioData";
import { Experience } from "../components/work/experience/experience";
import { Work } from "../components/work/work";

export const Homepage = ({data, location}) => {
    return <Layout location={location} title={"Home"}>
      <SEO />
      <section id="Home">
        <Hero />
      </section>
      <section id="About">
        <About />
        <Work />
        <ContactCTA />
      </section>
    </Layout>
};


export default Homepage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
