import { Icon, ILink } from "./projectsData"
// import masterThesis from "./portfolioImages/masterthesis.jpg";
export interface IPortfolioItemGroup {
    startYear?: string;
    endYear: string;
    title?: string;
    items: IPortfolioItem[];
}

export interface IPortfolioItem {
    name: string;
    company: string;
    image?: string;
    description: string;
    categories: PortfolioCategory[],
    tags: Tag[],
    links?: ILink[]
}

export interface Tag {
    name: string;
    type: TechnologyType
}

export enum PortfolioCategory {
    Featured,
    Web,
    Games,
    CSharp
}

export enum TechnologyType {
    Language,
    Library,
    Tool,
    Methodology
}

const cSharp: Tag = { name: "C#", type: TechnologyType.Language };
const dotNet: Tag = { name: ".Net", type: TechnologyType.Language };
const mvc: Tag = { name: ".ASP.Net MVC", type: TechnologyType.Language };
const aspNetCoreMvc: Tag = { name: ".ASP .Net Core/MVC", type: TechnologyType.Language };
const typescript: Tag = { name: "Typescript/JS", type: TechnologyType.Language };
const postCss: Tag = { name: "CSS/PostCSS", type: TechnologyType.Language };

const nHibernate: Tag = { name: "NHibernate", type: TechnologyType.Library };
const postgreSql: Tag = { name: "PostgreSQL", type: TechnologyType.Library };
const angularJs: Tag = { name: "AngularJS", type: TechnologyType.Library };
const react: Tag = { name: "React", type: TechnologyType.Library };
const sqlServer: Tag = { name: "MSSQL Server", type: TechnologyType.Library };
const selenium: Tag = { name: "Selenium", type: TechnologyType.Library };
const episerver: Tag = { name: "Optimizely CMS", type: TechnologyType.Library };
const epiCommerce: Tag = { name: "Optimizely Commerce", type: TechnologyType.Library };
const identityServer: Tag = { name: "IdentityServer4", type: TechnologyType.Library };
const nunit: Tag = { name: "NUnit", type: TechnologyType.Library };
const autofac: Tag = { name: "Autofac", type: TechnologyType.Library };

const castleWindsor: Tag = { name: "Castle Windsor", type: TechnologyType.Library };
const azure: Tag = { name: "Azure", type: TechnologyType.Tool };
const teamCity: Tag = { name: "TeamCity", type: TechnologyType.Tool };
const dicom: Tag = { name: "DICOM", type: TechnologyType.Tool };

export function GetPortfolioData(): IPortfolioItemGroup[] {
    return [
        {
            endYear: "from 2018",
            title: "Forte Digital",
            items: [
                {
                    company: "farmasiet.no",
                    name: "E-Commerce",
                    categories: [],
                    description: "E-commerce platform for leading Norwegian online pharmacy, selling OTC medicine, as well as prescribed drugs.",

                    tags: [cSharp, dotNet, azure, epiCommerce, typescript, react, identityServer, selenium],
                    // image: masterThesis
                },
                {
                    company: "munchmuseet.no",
                    name: "Munch Museum",
                    categories: [],
                    description: "New CMS solution for Museum of Edvard Munch in Oslo, built with EPiServer/Optimizely DXP platform.",

                    tags: [cSharp, dotNet, mvc, episerver, react, postCss],
                    // image: masterThesis
                },
                {
                    company: "kongsberg.com",
                    name: "KONGSBERG",
                    categories: [],
                    description: "EPiServer CMS-based new digital platform, uniting all existing KONGSBERG brands into single, consistent place with all KONGSBERG's news, articles, magazines, job offers and more.",

                    tags: [cSharp, dotNet, mvc, episerver, react, postCss],
                    // image: masterThesis
                }]
        },
        {
            title: "j-labs",
            endYear: "2018",
            startYear: "2017",
            items: [
                {
                    company: "INNERGY",
                    name: "",
                    categories: [PortfolioCategory.Games, PortfolioCategory.Featured],
                    description: "The ERP system dedicated to wooden industry, covering whole process of sales, estimation, production, warehouse management & other for woodworking & custom furniture production companies.",

                    tags: [cSharp, sqlServer, azure, nHibernate, typescript, angularJs]
                    // image: masterThesis
                }]
        },
        {
            endYear: "2018",
            title: "Master thesis",
            items: [
                {
                    company: "Realtime, soft body game physics engine",
                    name: "AGH University of science and technology",
                    categories: [PortfolioCategory.Games, PortfolioCategory.Featured],
                    description: "Realtime game physics engine supporting both rigid and soft body physics. Master thesis project at AGH University of Science and Technology.",

                    tags: [cSharp, nunit],
                    links: [
                        { icon: Icon.GITHUB, url: "https://github.com/kpkozak/softbody-physics" }
                    ]
                    // image: masterThesis
                }]
        },
        {
            endYear: "2017",
            startYear: "2016",
            title: "Ailleron",
            items: [
                {
                    company: "ASP.Net MVC app for financial sector",
                    categories: [],
                    description: "Development of ASP.Net MVC app for client from financial sector in Great Britain.",
                    name: "",
                    tags: [cSharp, mvc, sqlServer, nHibernate, castleWindsor, selenium],

                }
            ]
        },
        {
            endYear: "2016",
            startYear: "2014",
            title: "Comarch SA",
            items: [
                {
                    company: "Comarch Teleradiology",
                    categories: [],
                    description: "First as an intern, and then as a developer, I was taking part in development of Comarch Teleradiology — the system supporting the processes of remotely describing radiological examinations and exchange of telemedicine data. My team was responsible for transmission and processing of medical imaging data and integrating with diagnostic imaging devices in accordance with DICOM standard.",
                    name: "Comarch healthcare",
                    tags: [cSharp, postgreSql, nHibernate, nunit, autofac],

                }
            ]
        }
    ];
};

